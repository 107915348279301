<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <ClienteAlterarSenhaModal
      ref="modal"
      @saveNewPassword="onChangePassword"
      @update:newPassword="
        val => dadosInputModal({ val, model: 'newPassword' })
      "
      @update:confirmNewPassword="
        val => dadosInputModal({ val, model: 'confirmNewPassword' })
      "
    />
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButtonMenu
            type="secundario"
            :options="planilhaOptions"
            @click="op => selectOperacao(op)"
          >
            Operação em planilha
          </FormButtonMenu>
          <FormButton text="Novo cliente" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :filters="filters"
        :sortDesc="sortDesc"
        ref="datatable"
        :sortBy="sortBy"
        search
        searchLabel="Nome do cliente"
        :searchDefault="nome"
        noHideFilters
        :auto-search="false"
        :itemsPerPage="perPage"
        :page="page"
        @click="onClick"
        @set-value-filter="setValueFilter"
        @set-sort="setSort"
        @set-sort-desc="setSortDesc"
        @set-pagination="setPagination"
      >
        <template v-slot:can_sell_to="{ row, col }">
          <Chip v-if="col" color="green" text="Sim" :small="true" />
          <Chip v-if="!col" color="cyan" text="Não" :small="true" />
        </template>
        <template v-slot:email="{ row }">
          <small>
            <b><i class="fas fa-envelope"></i></b> {{ row.email }}
            <br />
            <b><i class="fas fa-phone"></i></b>{{ row.phone }}
          </small>
        </template>
        <template v-slot:register_date="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:idclient="{ row }">
          <FormButtonMenu
            icon
            :options="optionsMenu(row)"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
    <transition name="slide-popup" mode="out-in">
      <EdicaoEmPlanilha
        v-if="modalEdicaoEmPlanilha.open"
        :modelo="modalEdicaoEmPlanilha.modelo"
        @close="modalEdicaoEmPlanilha.open = false"
      />
    </transition>
  </div>
</template>

<script>
import { CARACTERISTICA_CLIENTE_LIST } from '@/store/actions/caracteristicaCliente'
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { FORMA_PAGAMENTO_LIST } from '@/store/actions/formaPagamento'
import { GRUPO_PRECO_LIST } from '@/store/actions/grupoPreco'
import { SEGMENTO_CLIENTE_LIST } from '@/store/actions/segmento'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import {
  CLIENTE_EXPORT_NAV_LOGS,
  CLIENTE_REMOVE
} from '@/store/actions/cliente'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import ApiWeb from '../../../service/api-web'
import ClienteAlterarSenhaModal from './ClienteAlterarSenhaModal.vue'
import EdicaoEmPlanilha from '@/components/EdicaoEmPlanilha'

export default {
  name: 'ClienteTable',
  components: {
    Chip,
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules,
    ClienteAlterarSenhaModal,
    EdicaoEmPlanilha
  },
  data: () => ({
    headers: [
      {
        text: 'Nome / Razão social',
        value: 'name'
      },
      { text: 'CPF / CNPJ', value: 'cpf_cnpj' },
      {
        text: 'Cadastro aprovado',
        align: 'center',
        value: 'can_sell_to'
      },
      {
        text: 'ID Cliente ERP',
        align: 'center',
        value: 'id_cliente_erp',
        sortable: false
      },
      {
        text: 'Contato',
        value: 'email'
      },
      {
        text: 'Data de cadastro',
        align: 'center',
        value: 'register_date'
      },
      {
        text: 'Opções',
        value: 'idclient',
        align: 'center',
        clickable: false
      }
    ],
    tableOptions: [
      { text: 'Editar', value: 'update', disable: false },
      { text: 'Deletar', value: 'delete', disable: false },
      { text: 'Endereços', value: 'list-address', disable: false },
      { text: 'Gestão de usuários', value: 'user-management', disable: false }
    ],
    filters: [
      { label: 'Email', query: 'email' },
      { label: 'CPF/CNPJ', query: 'cpf' },
      { label: 'Id ERP', query: 'id_erp' },
      { label: 'Telefone', query: 'telefone' },
      {
        label: 'Cadastro aprovado',
        query: 'aprovado',
        default: '',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Sim', value: 'true' },
          { text: 'Não', value: 'false' }
        ]
      },
      {
        label: 'Possui ID ERP',
        query: 'possui_id_erp',
        default: '',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Sim', value: 'true' },
          { text: 'Não', value: 'false' }
        ]
      }
    ],
    modulosForm: [
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ],
    newPassword: '',
    confirmNewPassword: '',
    idusuario: '',
    email: '',
    planilhaOptions: [
      {
        text: 'Editar atendente padrão',
        value: 'planilha_vinculo_atendente_padrao'
      }
    ],
    modalEdicaoEmPlanilha: {
      open: false,
      modelo: 'planilha_vinculo_atendente_padrao'
    },
    cpf: '',
    id_erp: '',
    telefone: '',
    aprovado: '',
    nome: '',
    sortBy: 'name',
    sortDesc: false,
    perPage: 10,
    page: 0
  }),
  created() {
    this.setFiltersDefault(this.$route.query)
    this.getPaymentMethod()
    this.getPriceGroup()
    this.getSegments()
    this.$store.dispatch(CARACTERISTICA_CLIENTE_LIST)
    this.getUserPermission()
  },
  watch: {
    getFornecedorId: {
      handler() {
        this.getPaymentMethod()
        this.getPriceGroup()
        this.getSegments()
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedor'
    ]),
    endpoint() {
      return `/api/v4/client/datatable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    filters() {
      return [
        { label: 'Email', query: 'email', default: this.email },
        { label: 'CPF/CNPJ', query: 'cpf', default: this.cpf },
        { label: 'Id ERP', query: 'id_erp', default: this.id_erp },
        { label: 'Telefone', query: 'telefone', default: this.telefone },
        {
          label: 'Cadastro aprovado',
          query: 'aprovado',
          default: this.aprovado,
          options: [
            { text: 'Todos', value: '' },
            { text: 'Sim', value: 'true' },
            { text: 'Não', value: 'false' }
          ]
        }
      ]
    }
  },
  methods: {
    getNavigationLogName() {
      let fornecSubstring = this.getFornecedorId.substring(0, 5)
      let filenameType = moment().format('YYYY_MM_DD_HHmmSSZZ')
      return `superon_log_navegacao_${fornecSubstring}_${filenameType}.csv`
    },
    getNavigationLogs() {
      this.$store.dispatch(CLIENTE_EXPORT_NAV_LOGS).then(data => {
        const filename = this.getNavigationLogName()
        this.createBlobLink(data, filename)
      })
    },
    getPaymentMethod() {
      this.$store.dispatch(FORMA_PAGAMENTO_LIST, {
        idfornecedor: this.getFornecedorId
      })
    },
    getPriceGroup() {
      this.$store.dispatch(GRUPO_PRECO_LIST, {
        idfornecedor: this.getFornecedorId
      })
    },
    getSegments() {
      this.$store.dispatch(SEGMENTO_CLIENTE_LIST, {
        idfornecedor: this.getFornecedorId
      })
    },
    // TODO: mover para mixin
    createBlobLink(data, filename) {
      const blob = new Blob([data], { type: 'text/plain' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'update':
          return this.onUpdate(item)
        case 'delete':
          return this.onDelete(item)
        case 'list-address':
          return this.onListAddress(item)
        case 'change-password':
          return this.openThePasswordChangeModal(item)
        case 'user-management':
          return this.onListUserManagement(item)
        case 'force-integration':
          return this.onForceIntegration(item)
      }
    },
    onNew() {
      this.$router.push('cliente/novo')
    },
    onClose() {
      this.$refs.datatable && this.$refs.datatable.reload()
    },
    onClick(item) {
      this.onUpdate(item)
    },
    onDelete(item) {
      const options = {
        text:
          'Lembrando que após o cliente ser removido ele não poderá realizar compras.',
        title: `Você confirma a exclusão deste cliente?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    onListAddress(item) {
      this.$router.push({
        path: `cliente/${item.idcliente}/lista/endereco`,
        query: {
          email: this.email,
          cpf: this.cpf,
          id_erp: this.id_erp,
          telefone: this.telefone,
          aprovado: this.aprovado,
          nome: this.nome,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          perPage: this.perPage,
          page: this.page
        }
      })
    },
    onListUserManagement(item) {
      this.$router.push(`cliente/${item.idcliente}/lista/usuario`)
    },
    onUpdate(item) {
      this.$router.push(`cliente/${item.idclient}`)
    },
    openThePasswordChangeModal(item) {
      this.idusuario = item.idcliente
      this.email = item.email
      this.$refs.modal.abrirModal()
    },
    onChangePassword() {
      const idfornecedor = this.getFornecedorId
      const idusuario = this.idusuario
      const novaSenha = this.newPassword
      const email = this.email

      if (novaSenha == this.confirmNewPassword) {
        ApiWeb.v4.cliente
          .resetPassordCustomers(idfornecedor, idusuario, novaSenha, email)
          .then(({ data }) => {
            this.$vueOnToast.pop('success', data.message)
          })
          .catch(() => {
            this.$vueOnToast.pop('error', 'Erro ao atualizar senha do cliente.')
          })
      }
    },
    async onForceIntegration(item) {
      const idfornecedor = this.getFornecedorId
      const {
        id_cliente_erp,
        idclient,
        cpf_cnpj,
        name,
        phone,
        email,
        birthday
      } = item

      if (!id_cliente_erp) {
        const payloadIntegration = {
          cpf_cnpj,
          name,
          phone,
          email,
          birthday,
          idclient,
          idfornecedor
        }

        ApiWeb.v1.usuario
          .forcarintegracao(idfornecedor, payloadIntegration)
          .then(({ data }) => this.$vueOnToast.pop('success', data.message))
          .catch(({ response: { data } }) => {
            this.$vueOnToast.pop('error', data.message)
          })
      }
    },
    dadosInputModal({ val, model }) {
      this[model] = val
    },
    delete(item) {
      this.$store
        .dispatch(CLIENTE_REMOVE, { idcliente: item.idcliente })
        .then(() => {
          this.$vueOnToast.pop('success', 'Cliente removido com sucesso')
          this.$refs.datatable.reload()
        })
        .catch(error => {
          this.$vueOnToast.pop('error', error)
        })
    },
    renderData(col) {
      return col ? moment(col).format('DD/MM/YYYY') : ''
    },
    getUserPermission() {
      ApiWeb.v2.usuario.navegacao().then(({ data }) => {
        const { permissao_alterarsenha } = data.permissao_alterar_senha[0]

        if (permissao_alterarsenha) {
          this.tableOptions.push({
            text: 'Alterar Senha',
            value: 'change-password',
            disable: false
          })
        }
      })
    },
    optionsMenu(row) {
      const result = [...this.tableOptions]
      const { id_cliente_erp } = row
      const { tag } = this.getFornecedor || []
      const notifyPajeNewClient = tag.includes('notify-paje-new-client')

      if (notifyPajeNewClient) {
        if (!id_cliente_erp) {
          result.push({
            text: 'Forçar integração',
            value: 'force-integration',
            disable: false
          })
        } else {
          result.push({
            text: 'Forçar integração',
            value: 'force-integration',
            disable: true
          })
        }
      }

      return result
    },
    selectOperacao(option) {
      const edicaoPlanilha = option.value.startsWith('planilha')
      if (edicaoPlanilha) {
        this.modalEdicaoEmPlanilha = {
          open: true,
          modelo: option.value
        }
        return
      }
    },
    setFiltersDefault(query) {
      const {
        email,
        cpf,
        id_erp,
        telefone,
        aprovado,
        nome,
        sortBy,
        sortDesc,
        perPage,
        page
      } = query
      this.email = email || ''
      this.cpf = cpf || ''
      this.id_erp = id_erp || ''
      this.telefone = telefone || ''
      this.aprovado = aprovado || ''
      this.nome = nome || ''
      this.sortBy = sortBy || 'name'
      this.sortDesc =
        typeof sortDesc === 'boolean' ? sortDesc : sortDesc === 'true'
      this.perPage = perPage || 10
      this.page = page || 0
    },
    setValueFilter(objFilter) {
      this.nome = objFilter.searchValue
      objFilter.filtrosAvancados.forEach(fd => {
        switch (fd.label) {
          case 'Email':
            return (this.email = fd.value)
          case 'CPF/CNPJ':
            return (this.cpf = fd.value)
          case 'Id ERP':
            return (this.id_erp = fd.value)
          case 'Telefone':
            return (this.telefone = fd.value)
          case 'Cadastro aprovado':
            return (this.aprovado = fd.value)
          default:
            return ''
        }
      })
    },
    setSort(sort) {
      this.sortBy = sort
    },
    setSortDesc(sortDesc) {
      this.sortDesc = sortDesc
    },
    setPagination(perPage, page) {
      this.perPage = perPage
      this.page = page
    }
  }
}
</script>
