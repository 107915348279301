<template>
  <div class="container-alterarsenha">
    <div class="overlay" v-if="showModal" @click="fecharModal"></div>
    <div class="container-modal" v-show="showModal">
      <div class="modal-header">
        <h4 class="modal-title">Alterar senha</h4>
        <small class="modal-subtitle">Alterar senha do usuário</small>
      </div>

      <div class="modal-body">
        <form>
          <div class="inputs">
            <label>Senha</label>
            <input
              type="password"
              v-model="newPassword"
              @input="
                event =>
                  dadosInput({
                    model: 'newPassword',
                    value: event.target.value
                  })
              "
              :class="erroSenha ? 'erro-input' : ''"
            />
            <label class="erro" v-show="erroSenha"
              ><i class="fas fa-info-circle"></i> Informe a senha</label
            >
          </div>

          <div class="inputs">
            <label>Confirme a senha</label>
            <input
              type="password"
              v-model="confirmNewPassword"
              @input="
                event =>
                  dadosInput({
                    model: 'confirmNewPassword',
                    value: event.target.value
                  })
              "
              :class="erroConfirmeSenha ? 'erro-input' : ''"
            />
            <label class="erro" v-show="erroConfirmeSenha"
              ><i class="fas fa-times-circle"></i> As senhas não
              coincidem</label
            >
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button class="btn btn-close" type="button" @click="fecharModal">
          Fechar
        </button>
        <button class="btn btn-save" type="button" @click="salvarNovaSenha">
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClienteAlterarSenhaModal',
  data() {
    return {
      showModal: false,
      newPassword: '',
      confirmNewPassword: '',
      erroSenha: false,
      erroConfirmeSenha: false
    }
  },

  emits: ['closeModal', 'saveNewPassword'],

  methods: {
    fecharModal() {
      this.showModal = false
      this.newPassword = ''
      this.confirmNewPassword = ''
      this.dadosInput({ model: 'newPassword', value: '' })
      this.dadosInput({ model: 'confirmNewPassword', value: '' })
    },
    abrirModal() {
      this.showModal = true
    },
    dadosInput({ model, value }) {
      if ((!this.newPassword && !this.confirmNewPassword) || this.newPassword) {
        this.erroSenha = false
      } else if (!this.newPassword) {
        this.erroSenha = true
      }

      if (this.confirmNewPassword !== this.newPassword) {
        this.erroConfirmeSenha = true
      } else {
        this.erroConfirmeSenha = false
      }

      this.$emit(`update:${model}`, value)
    },
    salvarNovaSenha() {
      if (
        this.newPassword == this.confirmNewPassword &&
        this.newPassword &&
        this.confirmNewPassword !== ''
      ) {
        this.$emit('saveNewPassword')
        this.fecharModal()
      } else {
        this.erroSenha = true
      }
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.container-modal {
  position: absolute;
  width: 600px;
  height: 392px;
  z-index: 9999;
  margin: 0 auto;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  top: 0;
  left: 0;
  right: 0;
}

.modal-header {
  padding: 40px 30px;
  background: #f7f9fa;
  border-bottom: 1px solid #e5e5e5;
}

.modal-title {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
  line-height: 1.42857143;
  color: rgb(134, 135, 138);
}

.modal-subtitle {
  color: #9d9fa2;
  font-size: 85%;
}

.modal-body {
  font-size: 13px;
  color: #6a6c6f;
  padding: 20px 30px;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
  font-weight: 700;
  text-align: left;
}

input {
  border: 1px solid #e4e5e7;
  height: 34px;
  padding: 6px 12px;
  border-radius: 4px;
}

input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.modal-footer {
  background: #f7f9fa;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  text-align: right;
}

.btn {
  border-radius: 3px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
}

.btn-close {
  color: #6a6c6f;
  border: 1px solid transparent;
  border-color: #e4e5e7;
  background: white;
}

.btn-save {
  margin-left: 10px;
  background-color: #34495e;
  border-color: #34495e;
  color: #ffffff;
}

.erro {
  color: #e74c3c;
  margin: 5px 0 0 0;
  font-weight: 400;
  padding-left: 5px;
}

.erro i {
  padding-right: 5px;
}

.erro-input {
  border-color: #e74c3c;
}

@media screen and (max-width: 768px) {
  .container-modal {
    width: 90%;
  }
}
</style>
