var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('ClienteAlterarSenhaModal',{ref:"modal",on:{"saveNewPassword":_vm.onChangePassword,"update:newPassword":function (val) { return _vm.dadosInputModal({ val: val, model: 'newPassword' }); },"update:confirmNewPassword":function (val) { return _vm.dadosInputModal({ val: val, model: 'confirmNewPassword' }); }}}),_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButtonMenu',{attrs:{"type":"secundario","options":_vm.planilhaOptions},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Operação em planilha ")]),_c('FormButton',{attrs:{"text":"Novo cliente","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"filters":_vm.filters,"sortDesc":_vm.sortDesc,"sortBy":_vm.sortBy,"search":"","searchLabel":"Nome do cliente","searchDefault":_vm.nome,"noHideFilters":"","auto-search":false,"itemsPerPage":_vm.perPage,"page":_vm.page},on:{"click":_vm.onClick,"set-value-filter":_vm.setValueFilter,"set-sort":_vm.setSort,"set-sort-desc":_vm.setSortDesc,"set-pagination":_vm.setPagination},scopedSlots:_vm._u([{key:"can_sell_to",fn:function(ref){
var row = ref.row;
var col = ref.col;
return [(col)?_c('Chip',{attrs:{"color":"green","text":"Sim","small":true}}):_vm._e(),(!col)?_c('Chip',{attrs:{"color":"cyan","text":"Não","small":true}}):_vm._e()]}},{key:"email",fn:function(ref){
var row = ref.row;
return [_c('small',[_c('b',[_c('i',{staticClass:"fas fa-envelope"})]),_vm._v(" "+_vm._s(row.email)+" "),_c('br'),_c('b',[_c('i',{staticClass:"fas fa-phone"})]),_vm._v(_vm._s(row.phone)+" ")])]}},{key:"register_date",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderData(col))+" ")]}},{key:"idclient",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.optionsMenu(row)},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[(_vm.modalEdicaoEmPlanilha.open)?_c('EdicaoEmPlanilha',{attrs:{"modelo":_vm.modalEdicaoEmPlanilha.modelo},on:{"close":function($event){_vm.modalEdicaoEmPlanilha.open = false}}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }